import React from "react";
import Typed from "react-typed";
import MainLayout from "../layout/MainLayout";
import { JobItem } from "../components/JobItem";
import { graphql } from "gatsby";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from "react-helmet";
import { JobList } from "../components/JobList";
import SEO from "../components/SEO";

export default ({ data }) => {
  const jobs = data.allFile.edges.map((edge) => edge.node.childMarkdownRemark);

  return (
    <MainLayout>
      <SEO title={`Salesforce jobs in London, UK`} />
      <div
        className="jumbotron jumbotron-fluid"
        style={{
          background: "#F7F9FC",
          borderBottom: "1px solid #DEE0F2",
          textAlign: "center",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <h1 className="display-4">Salesforce Jobs in London, UK</h1>
              <div className="lead">A list of Salesforce-friendly companies hiring in London, United Kingdom.</div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <JobList jobs={jobs} />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export const query = graphql`
  {
    allFile(
      sort: { fields: childMarkdownRemark___frontmatter___date, order: DESC }
      filter: {
        sourceInstanceName: { eq: "jobs" }
        childMarkdownRemark: { frontmatter: { hidden: { eq: false }, location: { eq: "London, UK" } } }
      }
    ) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              company {
                title
                id
              }
              dateFromNow: date(fromNow: true)
              date
              roleType
              location
              sponsored
              title
              slug
              # tags
            }
            html
          }
        }
      }
    }
  }
`;
